import React from "react";
import { useNavigate } from "react-router-dom";
import "./italian.css";

const Italian = () => {
  const navigate = useNavigate();

  return (
    <div className="italian" id="it">
      <div className="container__de">
        <img
          src="https://i.postimg.cc/q7FDLBRv/victor-emmanuel-ii-monument-rome-sunset-italy.jpg"
          alt="rome"
        />
        <div className="col-2__de">
          <h2>Italijanski jezik</h2>
          <span className="line"></span>
          <p>
            Italijanski jezik je jedan od najromantičnijih i najlepših jezika na
            svetu, sa preko 85 miliona govornika koji žive u Italiji,
            Švajcarskoj, San Marinu i drugim delovima sveta.
          </p>
          <p>
            Učenje italijanskog jezika može biti veoma zabavno i uzbudljivo
            iskustvo. Italijanski jezik je veoma melodičan i lep. Učenje
            italijanskog jezika može vam pomoći u pronalaženju posla u poslovnim
            granama kao što su moda, turizam i gastronomija, što ga čini važnim
            jezikom u ovim oblastima.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-italijanskog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};

export default Italian;
