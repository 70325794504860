import React from "react";
import "./introduction.css";

const Introduction = () => {
  const handleTopClick = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="intro__main">
      <div className="intro__container">
        <h1 className="page-title text-center">
          Zbog čega se mi razlikujemo od drugih?
        </h1>
        <div className="container__left">
          <img
            className="intro__Logo"
            src="https://i.postimg.cc/SQDFVPcs/2825784-20544-1.jpg"
            alt="LevelUp"
            onClick={() => handleTopClick()}
          />

          <h2 className="title_test__h2">
            Naš cilj je da omogućimo pristupačnu cenu kako bi svi koji žele da
            nauče strani jezik mogli to da učine bez obzira na svoj budžet.
            <br />
            <br />
            Odlučili smo se za da cena za sve jezike i za sve nivoe bude ista,
            tako da možete birati koji ćete jezik učiti ne razmišljajući o ceni.
          </h2>
        </div>
        <img
          className="intro__wow"
          src="https://i.postimg.cc/44pvcR6j/29595995-433.jpg"
          alt="LevelUp"
          onClick={() => handleTopClick()}
        />

        <div className="container__right">
          <h2 className="title_test__h2">
            Pružamo Vam jedinstvenu priliku da usavršite Vaše jezičke
            sposobnosti iz udobnosti Vašeg doma i uz prilagođen raspored učenja
            prema sopstvenim potrebama.
          </h2>

          <img
            className="intro__Logo2"
            src="https://i.postimg.cc/wvP4mGWB/2776319-398114-PCPMNP-586.jpg
          "
            alt="LevelUp"
            onClick={() => handleTopClick()}
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
