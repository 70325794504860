import React from "react";
import { useNavigate } from "react-router-dom";
import "./about.css";

function About() {
  const navigate = useNavigate();

  return (
    <section className="About__section">
      <div className="about">
        <div className="titleHolder">
          <h1>Zašto baš mi ?</h1>
          <h2>
            Nudimo Vam različite mogućnosti a, granice postavljate samo Vi!
          </h2>
        </div>
        <div className="container__about">
          <div className="box">
            <img
              src="https://i.postimg.cc/kgdVTKSL/young-teacher-paying-attention-her-students-while-having-some-coffee.jpg"
              alt="img"
            />
            <h3>Visoka produktivnost</h3>
            <p>
              Naš fokus je na poboljšanju Vaših jezičkih nedostataka i radimo
              naporno kako bismo u najkraćem mogućem roku dostigli Vaš cilj.
              Učenje jezika nikada nije bilo jednostavnije i pristupačnije -
              iskoristite naš online program jezika i usavršite svoje znanje
              stranog jezika. Prilagođavamo nastavu i grupe prema Vašim
              potrebama i slobodnom vremenu.
            </p>
          </div>

          <div className="box">
            <img
              src="https://i.postimg.cc/sfSbQjbf/little-girl-participating-online-classes.jpg"
              alt="img"
            />
            <h3>Sistem učenja</h3>
            <p>
              Uživo predavanja online su jedan od najboljih načina za učenje
              jezika, a mi Vam nudimo upravo to - sistem učenja sa uživo
              predavanjima online. Naši iskusni i kvalifikovani nastavnici su tu
              da Vas vode kroz proces učenja jezika, prilagođavajući se Vašim
              individualnim potrebama i ritmu učenja. Zahvaljujući ovom načinu
              učenja, možete ostvariti maksimalni napredak i postići svoje
              ciljeve. Iskoristite naše efikasne metode i tehnike, koje su
              prilagođene svim nivoima znanja jezika, i usavršite svoje vještine
              komunikacije na engleskom, njemačkom, francuskom ili nekom drugom
              jeziku, iz udobnosti svog doma. Pridružite se našem online učenju
              jezika sa uživo predavanjima i otvorite vrata novim mogućnostima!
            </p>
          </div>
          <div className="box" onClick={() => navigate("ChooseUs")}>
            <img
              src="https://i.postimg.cc/fyGqzTGP/close-up-kid-typing-keyboard.jpg"
              alt="img"
            />
            <h3>Jednostavan pristup</h3>
            <p>
              Kako bismo preneli proces učenja u moderno doba, neophodno je
              prihvatiti korišćenje inovativnih metoda i modernih aplikacija. U
              našoj nastavi, fokusiramo se na korišćenje minimalnog broja
              aplikacija koje omogućavaju lakši pristup i bolju komunikaciju.
              <strong>#Slack</strong> aplikacija nam omogućava upravo to. Ovaj
              alat za komunikaciju je neophodan za pružanje kvalitetne online
              nastave, čime se postiže efikasnije učenje i poboljšava kvalitet
              nastave. Iskoristite prednosti korišćenja samo jedne aplikacije za
              učenje jezika i usavršite svoje znanje, jednostavno i efikasno.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
