import React from "react";
import "./classesMain.css";

import Swipe from "../../componnents/swipe/Swipe";
import Introduction from "../introduction/Introduction";
import Video from "../../componnents/video/Video";
import ContactTestGrid from "../contact/ContactTestGrid";

const ClassesMain = () => {
  return (
    <div>
      <Introduction />
      <Swipe />
      <Video />
      <ContactTestGrid />
    </div>
  );
};

export default ClassesMain;
