import React from "react";
import { FaQuestion } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./price.css";

const Price = () => {
  const navigate = useNavigate();

  return (
    <div className="video" id="video">
      <div className="container__video">
        <div className="col-1">
          <h2 className="levelUp">
            Zašto baš mi
            <FaQuestion />
          </h2>
          <h2 className="levelUp">Level up škola stranih jezika</h2>
          <h3>
            Ukoliko želite da unapredite svoje znanje stranih jezika, pogledajte{" "}
            <strong>kratak video</strong> i saznajte kako Vam možemo pomoći da
            postignete svoje ciljeve{" "}
          </h3>
        </div>
        <div className="col-2">
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/6OS0eVImpV8`}
            title="Skola stranij jezika Level Up"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="btn__div">
        <button className="button__video" onClick={() => navigate("/Kontakt")}>
          Počnite sa učenjem <hr />
        </button>
      </div>
    </div>
  );
};

export default Price;
