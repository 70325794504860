import React from "react";
import { useNavigate } from "react-router-dom/dist";
import "./swipe.css";

function Swipe() {
  const navigate = useNavigate();

  return (
    <div className="pricing__main__container">
      <div class="pricing-card">
        <div class="card-left">
          <h2>Grupna nastava</h2>
          <p>
            <span class="euro">50</span>€
          </p>
        </div>
        <div class="card-right">
          <h2>Mesečna pretplata</h2>
          <ul>
            <li>&mdash; Predavanja 3x Sedmično </li>
            <li>&mdash; 1h vreme nastave</li>
            <li>&mdash; Mogućnost odabira termina</li>
            <li>&mdash; Individualna posvećenost</li>
            <li>&mdash; Sertifikat o pohađanju</li>
            <li>&mdash; Ne promenjena cena tokom učenja</li>
            <li>&mdash; 24/7 Tehička podrska</li>
          </ul>
          <button className="button" onClick={() => navigate("/contact")}>
            Prijavite se
          </button>
        </div>
      </div>
      <div class="pricing-card">
        <div class="card-left">
          <h2>Individualna nastava</h2>
          <p></p>
        </div>
        <div class="card-right">
          <h2>Po potrebi polaznika</h2>
          <ul>
            <li>&mdash; Odabir termina </li>
            <li>&mdash; Odabir dužine nastave</li>
            <li>&mdash; Mogućnost biranja predavača</li>
            <li>&mdash; Individualna posvećenost</li>
            <li>&mdash; Fokus na prioritete polaznika</li>
            <li>&mdash; Sertifikat o pohađanju</li>
            <li>&mdash; Ne promenjena cena tokom učenja</li>
            <li>&mdash; 24/7 Tehička podrska</li>
          </ul>
          <button className="button" onClick={() => navigate("/contact")}>
            Prijavite se
          </button>
        </div>
      </div>
    </div>
  );
}

export default Swipe;
