import React from "react";
import "./services.css";
import { IoMdArrowDropright } from "react-icons/io";
import { ImArrowDown } from "react-icons/im";
import ContactTestGrid from "../contact/ContactTestGrid";
import Languages from "../../componnents/languages/Languages";
import LanguagesNew from "../../componnents/languages/LanguagesNew";

const Services = () => {
  return (
    <div className="pricing-container">
      <main id="home" className="contents">
        <div className="container__price">
          <h1 className="page-title text-center">
            Kako brzo naučiti strani jezik kod kuće?
          </h1>
          <h2 className="title_test__h2">
            Naš nastavni program je strukturiran na način koji naglašava
            praktičnu primenu jezika, umesto da se fokusira samo na gramatiku i
            pravila.
          </h2>
          <p className="title_test__p">
            <IoMdArrowDropright className="icon__arow" /> Naši programi za
            učenje stranog jezika od kuće bazirani su na predavanjima uživo i
            nude Vam priliku usavršite znanje jezika na interaktivan i
            personalizovan način. <br />{" "}
            <IoMdArrowDropright className="icon__arow" /> Svi programi su
            prilagođeni individualnim potrebama i nivoima znanja polaznika, tako
            da možete biti sigurni da ćete napredovati u učenju.
          </p>
          <ul className="timeline">
            <li className="timeline-line"></li>
          </ul>
          <ul className="timeline">
            <li className="timeline-line"></li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading1">
                  Online učenje jezika
                  <div className="timeline-date">
                    <i class="fa fa-clock-o"></i> Zbog čega je online nastava
                    pravi izbor za učenje stranih jezika!
                  </div>
                </div>
                <div className="col-xs-4">
                  <img
                    className="img-responsive1"
                    src="https://i.postimg.cc/3RrXBBgy/education.jpg"
                    alt="img"
                  />
                </div>
                <div className="timeline-badge">
                  <a href="/contact"> </a>
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    Benifiti koje vam donosi online nastava
                  </div>
                  <div className="timeline-content">
                    <p>
                      <strong>Online nastava</strong> na{" "}
                      <strong>Level Up Akademiji</strong> omogućava učenicima da
                      prilagode svoj raspored učenja sopstvenom tempu. Online
                      programi naše akademije su prilagođeni svačijem budžetu i
                      idealno su rešenje za sve one koji nemaju mnogo vremena za
                      dodatno usavršavanje.
                    </p>
                    <p>
                      Naša edukacija Vam omogućava da sami napravite raspored
                      koji vama odgovara i da sami odredite tempo rada koji će
                      biti prilagođen Vašim svakodnevnim obavezama. Bez obzira
                      da li ste zaposleni ili imate druge obaveze,
                      prilagodljivost rasporeda na akademiji
                      <strong> Level Up </strong>
                      će vam biti više nego korisna.
                    </p>
                    <p>
                      <strong>Cilj i ideja</strong> naše akademije je u
                      <strong> procesu edukacije</strong>. Način i pristup
                      polaznicima nas izdvajaju od drugih. Naši profesori su
                      pažljivo odabrani kako bi ispunili sve uslove koji će
                      našim polaznicima omogućiti brz napredak bez obzira na
                      njihov jezički nivo.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div className="timeline-content">
                  <div className="timeline-heading1">Level Up Akademija</div>
                  <h2 className="timeline-heading1__h1">
                    Vaš pristup kvalitetnom obrazovanju stranih jezika
                  </h2>
                  <p>
                    Krenite sa učenjem{" "}
                    <strong>
                      {" "}
                      engleskog, nemačkog, francuskog, italijanskog, španskog
                      ili srpskog jezika
                    </strong>{" "}
                    od kvalifikovanih nastavnika i iskusnih stručnjaka.
                  </p>

                  <p>
                    <strong>Level Up Online Akademija </strong> pruža Vam
                    priliku da se povežete sa jezikom i kulturom koju želite
                    naučiti, pritom vam omogućavajući da postanete
                    samopouzdaniji i spretniji u svojim jezičkim veštinama.
                  </p>
                  <div className="timeline-embed">
                    <iframe
                      width="420"
                      height="250"
                      src="https://www.youtube.com/embed/6OS0eVImpV8"
                      title="Skola stranij jezika Level Up"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div id="services" class="timeline-heading1">
                  Dečji Program
                </div>
                <h2 className="timeline-heading1__h1">
                  Kvalitetno obrazovanje stranih jezika za vaše najmlađe iz
                  udobnosti vašeg doma!
                </h2>
                <div className="timeline-content">
                  <p>
                    Naš program se fokusira na prevazilaženju jezičkih barijera
                    kod dece na interaktivan i zabavan način, pružajući im
                    znanja koja će im pomoći da prate školski program i ujedno
                    napreduju u učenju stranog jezika.
                  </p>
                  <img
                    className="img-responsive"
                    src="https://i.postimg.cc/T1LTqRNF/portrait-little-sportive-girl-with-rugby-ball-looking-laptop.jpg"
                    alt="girl"
                  />
                  <p>
                    {" "}
                    Ovaj pristup je ključan za uspešno savladavanje stranih
                    jezika, jer pomaže deci da stvore samopouzdanje i
                    razumevanje jezika na način koji odgovara njihovom uzrastu.
                  </p>
                  <div className="col-xs-4">
                    <img
                      className="img-responsive"
                      src="https://i.postimg.cc/nzNhjHpc/education-study-childhood-skill-word.jpg"
                      alt="classes"
                    />
                  </div>
                  <p>
                    Sa našim Dečjim programom učenja stranih jezika, roditelji
                    imaju potpunu kontrolu i nadzor nad napretkom svoje dece.
                  </p>
                  <p>
                    Naš program nudi redovno praćenje napretka vaše dece, što
                    Vam omogućava da budete u toku sa njihovim uspehom i da
                    preduzmete odgovarajuće mere kako bi se osiguralo da ostvare
                    svoje ciljeve u učenju stranog jezika.
                  </p>
                  <h2 className="timeline-heading1__h2">
                    Pomozite Vašoj deci da premoste jezičke barijere na
                    interaktivan i zabavan način, pritom im pružajući alate i
                    znanja koja će im pomoći da postanu samopouzdaniji i veštiji
                    u učenju stranog jezika.
                  </h2>
                  <h2 className="timeline-heading1__h1">
                    Vaša deca zaslužuju najbolje <br />
                    <a className="prijava" href="/contact">
                      Prijavite se{" "}
                    </a>
                  </h2>
                  <p></p>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div id="services" class="timeline-heading1">
                  Poslovna edukacija (individualni program)
                </div>
                <h2 className="timeline-heading1__h1">
                  Kroz naš program poslovne edukacije pružićemo Vam visoko i
                  kvalitetno obrazovanje koje će vam pomoći da uspešno
                  komunicirate sa kolegama, klijentima i partnerima iz drugih
                  zemalja i kultura.
                </h2>
                <img
                  className="img-responsive"
                  src="https://i.postimg.cc/QCKq7xvH/languages-with-english-german-french-spanish-checkboxes-with-red-pencil.jpg"
                  alt="img"
                />
                <div className="timeline-content">
                  <p>
                    Naš program poslovne edukacije se prilagođava različitim
                    granama poslovanja, pružajući vamam specifično znanje koje
                    je potrebno u vašem poslovnom okruženju. Bez obzira da li se
                    bavite Medicinom, IT, marketingom, pravom ili bilo kojom
                    drugom granom poslovanja.
                  </p>
                  <div className="col-xs-4">
                    <img
                      className="img-responsive"
                      src="https://i.postimg.cc/HnmQr40P/portrait-attractive-young-woman-using-laptop.jpg"
                      alt="img"
                    />
                  </div>
                  <p>
                    Omogućavamo Vam da lakše položite ispite potrebne za
                    dobijanje vize za rad u inostranstvu, dok Vam istovremeno
                    pomažemo da se uspešno prilagodite i postanete uspešni u
                    svom poslu.
                  </p>
                  <p>
                    Nakon završetka određenog nivoa programa, naši polaznici
                    dobijaju <strong>sertifikat</strong> o pohađanju, koji će
                    Vam biti od velike koristi u daljoj karijeri. Naš sertifikat
                    predstavlja potvrdu da ste stekli znanja i veštine koje su
                    neophodne u vamašoj poslovnoj oblasti.
                  </p>
                  <img
                    className="img-responsive"
                    src=" https://i.postimg.cc/28b83H7H/business-woman-shows-thumb-up-sitting-desk-success-concept.jpg"
                    alt="img"
                  />
                  <h2 className="timeline-heading1__h1">
                    Uzmite kontrolu nad vašom karijerom <br />
                    <a className="prijava" href="/contact">
                      Prijavite se{" "}
                    </a>
                  </h2>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div id="services" class="timeline-heading1">
                  Grupni online program
                </div>
                <div className="timeline-content">
                  <div className="col-xs-4">
                    <img
                      className="img-responsive1"
                      src="https://i.postimg.cc/qBPKQCqv/business-people-teamwork-teamwork-power-successful-meeting-collaboration-relation-concept-social-net.jpg"
                      alt="img"
                    />
                  </div>
                  <p>
                    Naš grupni program je osmišljen tako da Vam pruži priliku da
                    učite u grupama od najviše 5 do 6 ljudi. Na taj način, naši
                    predavači mogu da se posvete svakom pojedincu posebno i da
                    im pomognu na najefikasniji mogući način.
                  </p>
                  <img
                    className="img-responsive"
                    src=" https://i.postimg.cc/Y0JGDjHg/female-hands-hold-card-paper-with-text-online-learning-yellow-space.jpg"
                    alt="img"
                  />
                  <p>
                    Sva predavanja su uživo, što znači da ćete biti u mogućnosti
                    da se direktno povežete sa svojim predavačem i ostalim
                    članovima grupe. Ovo je savršena prilika za poboljšanje
                    Vašeg govornog jezika i prevazilaženje jezičke barijere.
                  </p>

                  <p>
                    Grupe su takođe koncipirane po uzrastu, tako da ćete biti u
                    grupi sa ljudima koji imaju slične potrebe i interese. To će
                    vam omogućiti da se fokusirate na ono što je vama najvažnije
                    i da ostvarite najbolje rezultate u učenju jezika.
                  </p>
                  <img
                    className="img-responsive"
                    src=" https://i.postimg.cc/7ZH3kbc4/online-training-button-modern-computer-keyboard.jpg"
                    alt="img"
                  />
                  <p>
                    Naša online platforma je vrlo jednostavna za korišćenje,
                    tako da vam neće biti potrebna nikakva dodatna oprema ili
                    tehničko znanje da biste se pridružili našem programu. Sve
                    što Vam je potrebno je računar ili mobilni telefon i dobra
                    internet konekcija.
                  </p>
                  <h2 className="timeline-heading1__h1">
                    Započnite svoj put ka uspešnom učenju i usavršavanju
                    jezičkih veština. <br />
                    <a className="prijava" href="/contact">
                      Prijavite se{" "}
                    </a>
                  </h2>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-badge">
                <a href="/contact"> </a>
              </div>
              <div className="timeline-panel">
                <div id="services" class="timeline-heading1">
                  Jednostavan pristup
                </div>
                <div className="timeline-content">
                  <div className="col-xs-4">
                    <img
                      className="img-responsive1"
                      src="https://i.postimg.cc/yY178L2G/learning.jpg"
                      alt="img"
                    />
                  </div>
                  <p>
                    Level Up Akademija je koncipirana tako da koristi samo jednu
                    aplikaciju, Slack, za održavanje predavanja. Sva predavanja
                    su snimljena i dostupna su vam u bilo koje vreme, tako da
                    možete učiti kada Vama to odgovara u slučaju da ne možete
                    prisustvovati uživo nastavi.
                  </p>
                  <img
                    className="img-responsive"
                    src="https://i.postimg.cc/pLw74SFw/slack-Logotransparent.png"
                    alt="img"
                  />
                  <p>
                    Slack je jednostavan za korišćenje i omogućava Vam da lako
                    komunicirate sa svojim predavačem i ostalim polaznicima.
                  </p>
                  <p>
                    Skidanje aplikacije je izuzetno lako i neće Vam oduzeti
                    mnogo vremena. Sve što treba da uradite je da kliknete na
                    link i preuzmete aplikaciju.
                  </p>
                  <div className="timeline-heading1__h1">
                    <a
                      href="https://slack.com/downloads/windows"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="prijava"
                    >
                      #Slack - Preuzmite program
                    </a>
                  </div>
                  <h2 className="timeline-heading1__h1">
                    Video intrukcije "kako se prijaviti ?"{" "}
                    <strong>korak po korak</strong> možete pronaći na linku
                    <br />
                    <ImArrowDown />
                    <br />
                    <a
                      className="prijava"
                      href="https://www.youtube.com/watch?v=6OS0eVImpV8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Video instrukcije{" "}
                    </a>
                  </h2>
                  <h2 className="timeline-heading1__h1">
                    Naša tehnička podrška je aktivna 24 sata dnevno, tako da se
                    u svakom momentu možete obratite za pomoć ako vam je
                    potrebna.
                  </h2>
                  <a
                    className="bottom__icons__services"
                    href="tel:+38163-165-7505"
                  >
                    <img
                      className="footer__bottom__img"
                      src="https://i.postimg.cc/gjDHpygj/Phone-Logo-Transparent.png"
                      alt="call"
                    />
                    +381 63/165-75-05
                  </a>
                  <a
                    className="bottom__icons__services"
                    href="mailto:info@onlineskola.rs"
                  >
                    <img
                      className="footer__bottom__img"
                      target="_blank"
                      rel="noopener noreferrer"
                      src="https://i.postimg.cc/vTH1L8Tc/Email-Logo-Transparent.png"
                      alt="email"
                    />
                    info@onlineskola.rs
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <ul className="timeline">
            <li className="timeline-line"></li>
          </ul>
        </div>
      </main>
      <LanguagesNew />

      <Languages />

      <ContactTestGrid />
    </div>
  );
};

export default Services;
