import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleTopClick = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div class="footer-basic">
      <footer>
        <div class="social">
          <a
            href="https://www.facebook.com/OnlineSkolaStranihJezika/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/SRRfD7fk/facebook.png"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/akademija_level_up/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/8CNmfV32/Instagram-Logo-Transparent.png"
              alt="instagram"
            />
          </a>
          <a
            href="https://www.tiktok.com/@akademija_level_up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/qRDRp4bS/tiktok-Emailpng.png"
              alt="tiktok"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCgbWI-zAU5fP0dV2Hmd1H9w"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/ZqKCzMbN/youtube-logo-png-2062.png"
              alt="youtube"
            />
          </a>
        </div>
        <hr className="footer__hr" />
        <ul class="list-inline">
          <li class="list-inline-item">
            <li onClick={() => handleTopClick()}>Početna</li>
          </li>
          <li class="list-inline-item" onClick={() => navigate("/O-nama")}>
            O&nbsp;nama
          </li>
          <li class="list-inline-item" onClick={() => navigate("/Usluge")}>
            Usluge
          </li>
          <li class="list-inline-item" onClick={() => navigate("/Nastava")}>
            Nastava
          </li>

          <li class="list-inline-item" onClick={() => navigate("/Kontakt")}>
            Kontakt
          </li>
        </ul>
        <hr className="footer__hr" />
        <p className="copyright">
          Za bilo kakva pitanja možete nas kontaktirati putem
        </p>
        <a className="bottom__icons" href="mailto:info@onlineskola.rs">
          <img
            className="footer__bottom__img"
            src="https://i.postimg.cc/vTH1L8Tc/Email-Logo-Transparent.png"
            alt="email"
          />
          info@onlineskola.rs
        </a>
        <div className="phone__bottom">
          <a className="bottom__icons" href="tel:+38163-165-7405">
            <img
              className="footer__bottom__img"
              src="https://i.postimg.cc/gjDHpygj/Phone-Logo-Transparent.png"
              alt="call"
            />
            +381 63/165-74-05
          </a>
          <a
            className="bottom__icons"
            href="https://wa.me/381631657405"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer__bottom__img"
              src="https://i.postimg.cc/gJw7Y258/whatsapp.png"
              alt="call"
            />
          </a>
          {/*<a
            className="bottom__icons"
            href="https://msng.link/o/?0642196396=vi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer__bottom__img"
              src="https://i.postimg.cc/XYzcnk01/viber.png"
              alt="call"
            />
          </a>*/}
        </div>
        <div className="logo__footer">
          <img
            src="https://i.postimg.cc/MTgzGLLk/Logo-finalnew-orange.png"
            alt="LevelUp"
            onClick={() => handleTopClick()}
          />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
