import React from "react";
import { useNavigate } from "react-router-dom";
import "./languagesMain.css";

const LanguagesNew = () => {
  const navigate = useNavigate();

  return (
    <div className="container__menu">
      <div className="titl__languages">
        <h2 className="">
          Želite da naučite novi jezik ili da unapredite vaše znaje?
        </h2>
        <h3>Odaberite neki od jezika koji Vam najvise odgovara.</h3>
      </div>
      <div className="ulDiv">
        <ul className="ul__menu">
          <li
            className="li__menu li__menu__en"
            onClick={() => navigate("/Online-učenje-engleskog-jezika")}
          >
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/C5znXzz7/united-kingdom.png"
              alt="en"
            />
            Engleski
          </li>
          <li
            className="li__menu li__menu__de"
            onClick={() => navigate("/Online-učenje-nemačkog-jezika")}
          >
            Nemački
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/VLXMbMBX/germany.png"
              alt="de"
            />
          </li>
          <li
            className="li__menu li__menu__it"
            onClick={() => navigate("/Online-učenje-italijanskog-jezika")}
          >
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/ZnnJ4QtF/italy.png"
              alt="it"
            />
            Italijanski
          </li>
          <li
            className="li__menu li__menu__sp"
            onClick={() => navigate("/Online-učenje-španskog-jezika")}
          >
            Španski
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/W4cWVJL5/spain.png"
              alt="sp"
            />
          </li>
          <li
            className="li__menu li__menu__fr"
            onClick={() => navigate("/Online-učenje-francuskog-jezika")}
          >
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/xdH5bRNX/france.png"
              alt="fr"
            />
            Francuski
          </li>
          <li
            className="li__menu li__menu__rs"
            onClick={() => navigate("/Online-učenje-srpskog-jezika")}
          >
            Srpski
            <img
              className="li__menu__img"
              src="https://i.postimg.cc/xdZfWNKY/serbia.png"
              alt="rs"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LanguagesNew;
