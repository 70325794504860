import React from "react";
import { useNavigate } from "react-router-dom";
import "./german.css";

const German = () => {
  const navigate = useNavigate();

  return (
    <div className="german" id="de">
      <div className="container__de">
        <img
          src="https://i.postimg.cc/k5vLvPSV/small-national-flags-germany-light-blurry-wall.jpg"
          alt="germany"
        />
        <div className="col-2__de">
          <h2>Nemački jezik</h2>
          <span className="line"></span>
          <p>
            Sa preko 100 miliona govornika koji žive u Nemačkoj, Austriji,
            Švajcarskoj i drugim delovima sveta, nemački jezik je jedan od
            najvažnijih i najrasprostranjenijih jezika u Evropi.
          </p>
          <p>
            Nemački jezik je važan jezik u poslovnom svetu, posebno u oblastima
            kao što su medicina tehnologija, nauka i inženjering. Poznavanje
            nemačkog jezika može vam pomoći u pronalaženju posla u ovim
            oblastima i poboljšati vaše šanse za uspeh u karijeri.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-nemačkog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};
export default German;
