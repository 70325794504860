import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { db } from "../../firebase";
import "./contact.css";
import firebase from "firebase/compat/app";

const Contact = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("Odaberite jezik");
  const [learning_Level, setLearning_Level] = useState("Odaberite nivo znanja");
  const [learning_Process, setlearning_Process] = useState(
    "Odaberite način učenja"
  );
  const [message, setMessage] = useState("Odaberite način učenja");

  const submitInfo = () => {
    console.log(
      name +
        lastName +
        email +
        phone +
        language +
        learning_Level +
        learning_Process +
        message
    );

    const emailContent = {
      name: name,
      lastName: lastName,
      email: email,
      phone: phone,
      language: language,
      learning_Level: learning_Level,
      learning_Process: learning_Process,
      message: message,
    };

    emailjs
      .send(
        "service_cznpko3",
        "template_xi8wrfn",
        emailContent,
        "CFBhklBn4uZLdW12w"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Vaš prijava je uspešno poslata 👍");
        },
        (error) => {
          console.log(error.text);
        }
      );
    emailContent.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection("contacts")
      .add({
        Ime: name,
        Prezime: lastName,
        Email: email,
        Telfon: phone,
        Odabrani_Jezik: language,
        Nivo_Znanja: learning_Level,
        Nacin_ucenja: learning_Process,
        Poruka: message,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        console.log("Vaš zahtev je uspešno poslat 👍");
      })
      .catch((error) => {
        alert(error.message);
      });

    setName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setLanguage("");
    setLearning_Level("");
    setlearning_Process("");
    setMessage("");
  };

  return (
    <div className="contac__main">
      <div className="body__contact">
        <div className="contact">
          <h1>Škola stranih jezika Level Up </h1>
          <img
            className="education__img"
            src="https://i.postimg.cc/66msfvtw/4611204-4690new.jpg"
            alt="education"
          />
          <h2>Prijavite se</h2>
          <div className="alert">Vaša poruka je uspešno poslata</div>
          <form className="contactForm" onSubmit={handleSubmit}>
            <p>
              <label>Ime</label>
              <input
                type="text"
                name="name"
                id="name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </p>
            <p>
              <label>Prezime</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />
            </p>
            <p>
              <label>E-mail Adresa </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>
            <p>
              <label>Broj telefona</label>
              <input
                type="text"
                name="phone"
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </p>
          </form>
          <div className="select__container">
            <div className="select_select">
              <select onChange={(e) => setLanguage(e.target.value)}>
                <option>Odaberite jezik</option>
                <option>Engeski</option>
                <option>Nemački</option>
                <option>Italijanski</option>
                <option>Španski</option>
                <option>Francuski</option>
                <option>Srpski</option>
              </select>
              <div className="select__arrow"></div>
            </div>
            <div class="select_select">
              <select onChange={(e) => setLearning_Level(e.target.value)}>
                <option>Odaberite nivo znanja</option>
                <option>Početni A1/A2</option>
                <option>Srednji B1/B2</option>
                <option>Napredni C1/C2</option>
              </select>
              <div class="select__arrow"></div>
            </div>
            <div class="select_select">
              <select onChange={(e) => setlearning_Process(e.target.value)}>
                <option>Odaberite način učenja</option>
                <option>Grupni</option>
                <option>Individualni</option>
              </select>
              <div class="select__arrow"></div>
            </div>
          </div>

          <p className="fullMsg">
            <label>Poruka</label>
            <textarea
              name="message"
              rows="5"
              id="message"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </p>
          <p className="full">
            <button className="btn__Contact" type="submit" onClick={submitInfo}>
              Pošalji
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
