import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBSjFEzubaHFx6R4PzgXsu5HzV6UxIk4VA",
  authDomain: "online-skola-8f1fa.firebaseapp.com",
  projectId: "online-skola-8f1fa",
  storageBucket: "online-skola-8f1fa.appspot.com",
  messagingSenderId: "836764506740",
  appId: "1:836764506740:web:3a10a7eed3e0998df6cf21"
});

var db = firebaseApp.firestore();

export { db };
