import "./App.css";
import Navbar from "./componnents/navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Languages from "./componnents/languages/Languages";
import Footer from "./componnents/footer/Footer";
import ScroolToTop from "./componnents/scroolToTop/ScroolToTop";
import ClassesMain from "./pages/Classes/ClassesMain";
import OnlineUcenjeEngleskogJezika from "./pages/section/OnlineUcenjeEngleskogJezika";
import OnlineUcenjeNemackogJezika from "./pages/section/OnlineUcenjeNemackogJezika";
import OnlineUcenjeFrancuskogJezika from "./pages/section/OnlineUcenjeFrancuskogJezika";
import OnlineUcenjeItalijanskogJezika from "./pages/section/OnlineUcenjeItalijanskogJezika";
import OnlineUcenjeSpanskogJezika from "./pages/section/OnlineUcenjeSpanskogJezika";
import OnlineUcenjeSrpskogJezika from "./pages/section/OnlineUcenjeSrpskogJezika";

function App() {
  return (
    <>
      <Router>
        <ScroolToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/O-nama" element={<About />} />
          <Route exact path="/Usluge" element={<ClassesMain />} />
          <Route exact path="/Nastava" element={<Services />} />
          <Route exact path="/Kontakt" element={<Contact />} />
          <Route exact path="/Strani-Jezici" element={<Languages />} />
          <Route
            exact
            path="online-učenje-engleskog-jezika"
            element={<OnlineUcenjeEngleskogJezika />}
          />
          <Route
            exact
            path="online-učenje-nemačkog-jezika"
            element={<OnlineUcenjeNemackogJezika />}
          />
          <Route
            exact
            path="/online-učenje-francuskog-jezika"
            element={<OnlineUcenjeFrancuskogJezika />}
          />
          <Route
            exact
            path="/online-učenje-italijanskog-jezika"
            element={<OnlineUcenjeItalijanskogJezika />}
          />
          <Route
            exact
            path="/online-učenje-španskog-jezika"
            element={<OnlineUcenjeSpanskogJezika />}
          />
          <Route
            exact
            path="/online-učenje-srpskog-jezika"
            element={<OnlineUcenjeSrpskogJezika />}
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
