import React from "react";
import SectionRs from "./SectionRs";

const OnlineUcenjeSrpskogJezika = () => {
  return (
    <div>
      <SectionRs />
    </div>
  );
};

export default OnlineUcenjeSrpskogJezika;
