import React from "react";
import { useNavigate } from "react-router-dom";
import "./spain.css";

const Spain = () => {
  const navigate = useNavigate();

  return (
    <div className="spain" id="sp">
      <div className="container__de">
        <img
          src="https://i.postimg.cc/gJh54CGT/german-girls-are-best.jpg"
          alt="spain"
        />
        <div className="col-2__de">
          <h2>Španski jezik</h2>
          <span className="line"></span>
          <p>
            Španski jezik je drugi po broju govornika u svetu, nakon kineskog,
            sa preko 500 miliona ljudi koji ga govore kao maternji jezik.
            Španski se govori u Španiji, Latinskoj Americi, delovima SAD, kao i
            u nekim drugim delovima sveta.
          </p>
          <p>
            Španija je jedna od najposećenijih turističkih destinacije u Evropi.
            Poznavanjem španskog jezika razvijete bolje komunikacione veštine,
            shodno tome poznavanje ovog jezika vam omogućava da razgovarate sa
            ljudima iz različitih kultura i da steknete nova prijateljstva.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-španskog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};

export default Spain;
