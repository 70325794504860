import React from "react";
import { useNavigate } from "react-router-dom";
import "./franch.css";

const Franch = () => {
  const navigate = useNavigate();

  return (
    <div className="franch" id="fr">
      <div className="container__de">
        <img
          src="https://i.postimg.cc/K8VT7zJx/famous-eiffel-tower-paris-with-gorgeous-colors.jpg"
          alt="rome"
        />
        <div className="col-2__de">
          <h2>Francuski Jezik</h2>
          <span className="line"></span>
          <p>
            Francuski jezik je jedan od najlepših i najromantičnijih jezika u
            svetu, koji se govori u Francuskoj, delovima Kanade, Belgije,
            Švajcarske, Afrike i drugim delovima sveta
          </p>
          <p>
            Francuska je poznata po svom bogatom kulturnom nasleđu, umetnosti,
            modi, gastronomiji i drugim atrakcijama. Poznavanje francuskog
            jezika može vam pomoći da se bolje integrišete u lokalnu kulturu i
            da uživate u autentičnom iskustvu.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-francuskog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};

export default Franch;
