import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactTestGrid from "../contact/ContactTestGrid";
import { BsDot } from "react-icons/bs";
import { RxTextAlignTop } from "react-icons/rx";
import { FaQuestion } from "react-icons/fa";

import "./section.css";

const SectionRs = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  function handleClick() {
    setVisible(!visible);
  }
  return (
    <section className="container-section-main">
      <div className="top-section">
        <h1>Naučite srpski jezik Online</h1>
        <h2>Savladajte jezik na brz i efikasan način</h2>
        <p></p>
      </div>
      <div className="section-main">
        <div className="left-section">
          <img
            src="https://i.postimg.cc/6pg71bLC/serbia-national-flag-hanging-fabric-banner-3d-rendering.jpg"
            alt="img"
          />
          <h2>
            Online nastava
            <br /> Učite srpski jezik iz Vama najprijatnije atmosfere.
          </h2>
          <p>
            Pripremili smo odličan nastavni program srpskog jezika koji je
            dizajniran posebno za Vaše potrebe prilagođen svim nivoima znanja,
            tako da i oni koji su početnici u učenju engleskog jezika mogu da se
            pridruže.{" "}
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Kontakt")}
          >
            Počnite sa učenjem
          </button>
        </div>
        <div className="right-section">
          <img
            className="img-right"
            src="https://i.postimg.cc/44PYTxQf/location-serbia-push-pin-map-closeup-marker-destination-travel-tourism-trip-concept-europe.jpg"
            alt="img"
          />

          <div className="section-right-Text">
            <div className="title-text-right">
              <img
                className="icons"
                src="https://i.postimg.cc/651TybPF/Serrbia-flag-icon.png"
                alt="srpki-jezik"
              />
              <h2>Srpski jezik</h2>
            </div>

            <hr />
            <h3>Prilagodjeni način predavanja</h3>
            <br />
            <p className="text-visible">
              Naš program se može pohađati u malim grupama ili individualno, što
              omogućava svakom polazniku da pronađe način učenja koji mu
              najbolje odgovara.
              <br />
              <br />
              Svi naši predavači su iskusni stručnjaci u oblasti srpskog jezika,
              pa će vaše znanje i veštine brzo napredovati...
              {!visible && (
                <span className="span-clicked" onClick={handleClick}>
                  <strong>Pogledaj više</strong>
                </span>
              )}
              <p className={visible ? "" : "text-hidden"}>
                <br />
                Program se zasniva na interaktivnom pristupu koji podstiče
                aktivno učenje i razvoj vaših veština razumevanja, govora,
                pisanja i čitanja.
                <br />
                <br />
                Naučićete da se sporazumete na srpskom jeziku u različitim
                situacijama, kao i da razumete izgovor i gramatiku jezika.
                <br />
                <br />
                Uvereni smo da će naš nastavni program doprineti Vašem uspehu i
                doneti Vam mnoge prednosti u Vašoj karijeri i životu uopšte.
                <br />
                <br />
                Radujemo se što ćemo biti deo vašeg putovanja u učenju srpskog
                jezika i što ćemo Vam pružiti podršku koja će Vam pomoći da
                dostignete vaše ciljeve.
                <br />
                <br />
                <span className="span-clicked" onClick={handleClick}>
                  {" "}
                  <strong>
                    <RxTextAlignTop />
                  </strong>
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className="english" id="eng">
        <div className="container__eng">
          <img
            src="https://i.postimg.cc/x84XwX0c/colleagues-having-video-conference-during-coronavirus-pandemic.jpg"
            alt="online-ucenje-jezika"
          />
          <div className="col-2__eng">
            <h2>Grupni program</h2>
            <span className="line"></span>
            <h4>
              Specijalni program za učenje stranih jezika online u grupama od
              najviše <strong>šest</strong> polaznika je sjajna opcija za sve
              one koji žele da unaprede svoje znanje jezika i usavrše svoje
              veštine, bez obzira na to da li su početnici ili već imaju
              određeno predznanje.
            </h4>
            <br />
            <h3>
              {" "}
              <BsDot />
              Kvalitetna podrška za napredak svakog polaznika.
            </h3>
            <p>
              Naš program je fokusiran na individualni pristup svakom polazniku,
              uz kontinuiranu podršku i praćenje napretka u učenju. Predavači su
              stručnjaci u svojoj oblasti, a naš interaktivni pristup uključuje
              različite metodologije i tehnologije za učenje jezika online, što
              omogućava da se učenje odvija <strong>brzo i efikasno</strong>.
            </p>
            <h3>
              {" "}
              <BsDot />
              Unapredite svoje znanje jezika iz udobnosti svog doma.
              <br />
              <br />
            </h3>
            <p>
              Ukoliko želite da poboljšate svoje znanje jezika i steknete
              samopouzdanje u govoru, naš program je prava stvar za vas.
              <br />
              Učenje stranih jezika od kuće nikada nije bilo lakše i efikasnije,
              a naš program je prilagođen svim nivoima znanja, od osnovnog do
              naprednog.
              <br />
              <br />
            </p>
            <h4>
              {" "}
              Iskoristite ovu sjajnu priliku i postanite vešti u govoru stranog
              jezika!
            </h4>
            <br />
            <br />
            <button
              className="button__language"
              onClick={() => navigate("/Kontakt")}
            >
              Prijavite se
            </button>
          </div>
        </div>
      </div>
      <div className="english" id="eng">
        <div className="container__eng">
          <img
            src="https://i.postimg.cc/0jm20B7j/cheerful-female-freelancer-with-laptop-workflow-planning-posing-street-cafe-successful-woman-glasses.jpg"
            alt="online-ucenje-jezika"
          />
          <div className="col-2__eng">
            <h2>Poslovna edukacija</h2>
            <h3>grupni i individualni program</h3>
            <span className="line"></span>
            <br />
            <h4>
              Učenje stranih jezika postalo je sve važnije u današnjem
              globalizovanom svetu. Naš specijalni program za učenje stranih
              jezika online pruža priliku polaznicima da nauče jezik iz
              udobnosti svog doma, a da istovremeno steknu potrebne veštine za
              posao u različitim sferama industrije.
            </h4>
            <br />
            <h3>
              <BsDot />
              Zašto izabrati naš program poslovne edukacije <FaQuestion />
            </h3>
            <p>
              <BsDot />
              <strong>
                {" "}
                Personalizovani planovi učenja za svakog polaznika kroz
                različite nivoe i teme, sa fokusom na specifične potrebe
                polaznika.
              </strong>
            </p>
            <p>
              <BsDot />{" "}
              <strong>
                Obezbeđujemo visokokvalitetno i interaktivno učenje stranog
                jezika.
              </strong>
            </p>
            <p>
              <BsDot />
              <strong>
                Personalizovani planovi učenja za svakog polaznika.
              </strong>
            </p>
            <p>
              <BsDot />
              <strong>
                Pružamo podršku i za pripremu različitih međunarodnih ispita.
              </strong>
            </p>
            <p>
              <BsDot />
              <strong>
                Mogućnost učenja iz različitih sfera industrije, uključujući
                medicinu, finansije, IT i druge.
              </strong>
            </p>
            <p>
              <BsDot />
              <strong>
                Naš program se prilagođava vremenu i tempu učenja polaznika,
                kako bi se omogućilo maksimalno napredovanje.
              </strong>
            </p>
            <br />
            <p></p>
            <h4>
              {" "}
              Unaprede znanje stranog jezika i povećaju svoje šanse za dobijanje
              posla u internacionalnom okruženju!
            </h4>
            <br />
            <br />
            <button
              className="button__language"
              onClick={() => navigate("/Kontakt")}
            >
              Prijavite se
            </button>
          </div>
        </div>
      </div>
      <div className="english" id="eng">
        <div className="container__eng">
          <img
            src="https://i.postimg.cc/CK6V49GV/teenage-girl-studying-via-video-conference-e-learning-with-teacher-classmates-computer-home-homescho.jpg"
            alt="online-ucenje-jezika"
          />
          <div className="col-2__eng">
            <h2>Progam za decu</h2>
            <h3>školski i predškolski uzrast</h3>
            <span className="line"></span>
            <br />
            <h4>
              Specijalni program za Naše najmladje. Ovaj program nudi podršku
              učenicima u savladavanju gradiva i razvijanju govornih veština na
              stranom jeziku.
            </h4>
            <br />
            <h3>
              {" "}
              <BsDot />
              Formiramo grupe po uzrastima kako bismo omogućili najbolje
              iskustvo za svako dete.
            </h3>
            <p>
              Naš program podržava decu u savladavanju gramatike, pravopisa i
              izgovora, a istovremeno im pruža priliku da se igraju i uče na
              zabavan način koristeći interaktivne igre i animacije kako bi se
              deca aktivno uključila u učenje stranog jezika.
            </p>
            <h3>
              {" "}
              <BsDot />
              Pružamo podršku deci u savladavanju gradiva koje se uče u školi.
              <br />
              <br />
            </h3>
            <p>
              Kroz naš program, deca će imati priliku da uče strani jezik kroz
              različite teme koje su relevantne za njihove godine i
              interesovanja. Naši nastavnici će se fokusirati na razvijanju
              govornih veština kod dece, a istovremeno će obezbediti da deca
              razumeju gramatiku i pravopis stranog jezika.
              <br />
              <h3>
                {" "}
                <BsDot />
                Roditelji mogu imati apsolutnu kontrolu i nadgledanje nad
                učenjem svoje dece.
              </h3>
              Naši iskusni nastavnici prate napredak dece i pružaju redovne
              povratne informacije o njihovom razvoju i uspehu. Pružamo podršku
              za decu u savladavanju gradiva koje se uče u školi i radimo na
              tome da deca aktivno koriste i govore strani jezik.
              <br />
              Naš program nudi mogućnost učenja stranog jezika od kuće, što
              roditeljima omogućuje veću fleksibilnost i smanjuje troškove
              putovanja i vremena provedenog u školi.
              <br />
              Koristeći naš program, deca će se lako naviknuti na govor stranog
              jezika i postati sigurniji u svojim govornim veštinama.
            </p>

            <h4>
              {" "}
              Naš cilj je da pomognemo deci da postignu bolje rezultate u školi
              putem individualizovanog pristupa učenju i podrške u savladavanju
              gradiva.
            </h4>
            <br />
            <br />
            <button
              className="button__language"
              onClick={() => navigate("/Kontakt")}
            >
              Učite sa nama
            </button>
          </div>
        </div>
      </div>
      <ContactTestGrid />
    </section>
  );
};

export default SectionRs;
