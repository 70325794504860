import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const handleTopClick = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };
  const dropdownRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDropdownClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar">
          <div className="navbar-container container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img
                src="https://i.postimg.cc/MTgzGLLk/Logo-finalnew-orange.png"
                alt="LevelUp"
                onClick={() => handleTopClick()}
              />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Početna
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/O-nama"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  O&nbsp;nama
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Usluge"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Usluge
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Nastava"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Nastava
                </NavLink>
              </li>
              <li
                className={`dropdown__main ${isDropdownVisible && "clicked"}`}
                ref={dropdownRef}
                onClick={handleDropdownClick}
              >
                {windowWidth <= 960 ? (
                  <button
                    className="strani__jezici"
                    onClick={handleDropdownClick}
                  >
                    {" "}
                    Jezici{" "}
                    <MdOutlineKeyboardArrowDown className="arrow__down" />{" "}
                  </button>
                ) : (
                  // eslint-disable-next-line
                  <a
                    // eslint-disable-next-line
                    href="javascript:void(0)"
                    className="strani__jezici"
                    onClick={handleDropdownClick}
                  >
                    {" "}
                    Jezici{" "}
                    <MdOutlineKeyboardArrowDown className="arrow__down" />{" "}
                  </a>
                )}
                {isDropdownVisible && (
                  <ul className="dropdown__list">
                    <li>
                      <NavLink
                        to="/online-učenje-engleskog-jezika"
                        href="/online-učenje-engleskog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        Engleski jezik
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/online-učenje-nemačkog-jezika"
                        href="online-učenje-nemačkog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        Nemački jezik
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/online-učenje-francuskog-jezika"
                        href="/online-učenje-francuskog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        Francuski jezik
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/online-učenje-italijanskog-jezika"
                        href="/online-učenje-italijanskog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        {" "}
                        Italijanski jezik{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/online-učenje-španskog-jezika"
                        href="/online-učenje-španskog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        Španski jezik
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/online-učenje-srpskog-jezika"
                        href="online-učenje-srpskog-jezika"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        Srpski jezik
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              {/* <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
                  <Link to='/Strani-Jezici' className='nav-links' onClick={closeMobileMenu} >
                    Jezici <AiFillCaretDown/>
                  </Link> 
                  {dropdown && <Dropdown />}
                </li>*/}
              <li className="nav-item">
                <NavLink
                  to="/Kontakt"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Kontakt
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
