import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};
const Testimonial = () => {
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h2 className="text_testemonial">Komentari nasih polaznika</h2>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          <Card img="https://i.postimg.cc/3NV2FJDN/Testemonialimg1.jpg" />
          <Card1 img="https://i.postimg.cc/PqTZfDMd/Whats-App-Image-2023-03-22-at-09-55-00.jpg" />
          <Card2 img="https://i.postimg.cc/W10Jdq0Y/Testemonialimg2.jpg" />
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ img }) => {
  return (
    <div
      className="card__style"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "#f2f2f2",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "3px solid #fbad30",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>
        Učenje jezika na Akademiji Level Up bilo je izuzetno zabavno iskustvo.
        Uz pomoć njihove podrške uspeo sam poboljšati svoj nemački jezik i
        osećam se samopouzdanije kada govorim.
      </p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "#fbad30" }}>
          Mladen Veljković
        </span>{" "}
        , Profesor Istorije
      </p>
    </div>
  );
};
const Card1 = ({ img }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "#f2f2f2",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "3px solid #fbad30",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>
        Akademija Level Up mi je pomogla da napokon savladam engleski jezik.
        Profesori su bili izuzetno strpljivi i koristili su različite metode
        učenja kako bih bolje razumela gradivo. Zahvaljujući njima, sada mogu
        komunicirati na engleskom bez straha.
      </p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "#fbad30" }}>
          Ana Jovandarić
        </span>{" "}
        , Diplomirani Ekonomista
      </p>
    </div>
  );
};
const Card2 = ({ img }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "3px solid #fbad30",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>
        Kada sam se pridružio <strong>Akademiji Level Up</strong>, tražio sam
        način da unapredim svoje poslovne veštine engleskog jezika. Hvala vam,{" "}
        <strong>Akademija Level Up</strong>, na pomoći i usavršavanju mog
        poslovnog engleskog. Preporučio bih ovaj program svakome ko traži brz i
        efikasan način za poboljšavanje svojih veština engleskog jezika u
        poslovnom svetu.
      </p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "#fbad30" }}>
          Jovan Srećković
        </span>{" "}
        , Database Administrator (DBA)
      </p>
    </div>
  );
};

export default Testimonial;
