import React, { useNavigate } from "react-router-dom";
import "./languagesMain.css";

const LanguagesMain = () => {
  const navigate = useNavigate();

  return (
    <div className="section__Languages">
      <div className="container__menu">
        <div className="titl__languages">
          <h2 className="">
            Želite da naučite novi jezik ili da unapredite Vaše znaje?
          </h2>
          <h3>Odaberite neki od jezika koji Vam najvise odgovara.</h3>
        </div>
        <div className="ulDiv">
          <ul className="ul__menu">
            <li
              className="li__menu li__menu__en"
              onClick={() => navigate("/online-učenje-engleskog-jezika")}
            >
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/C5znXzz7/united-kingdom.png"
                alt="en"
              />
              Engleski
            </li>
            <li
              className="li__menu li__menu__de"
              onClick={() => navigate("/online-učenje-nemačkog-jezika")}
            >
              Nemački
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/VLXMbMBX/germany.png"
                alt="de"
              />
            </li>
            <li
              className="li__menu li__menu__it"
              onClick={() => navigate("/online-učenje-italijanskog-jezika")}
            >
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/ZnnJ4QtF/italy.png"
                alt="it"
              />
              Italijanski
            </li>
            <li
              className="li__menu li__menu__sp"
              onClick={() => navigate("/online-učenje-španskog-jezika")}
            >
              Španski
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/W4cWVJL5/spain.png"
                alt="sp"
              />
            </li>
            <li
              className="li__menu li__menu__fr"
              onClick={() => navigate("/online-učenje-francuskog-jezika")}
            >
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/xdH5bRNX/france.png"
                alt="fr"
              />
              Francuski
            </li>
            <li
              className="li__menu li__menu__rs"
              onClick={() => navigate("/online-učenje-srpskog-jezika")}
            >
              Srpski
              <img
                className="li__menu__img"
                src="https://i.postimg.cc/xdZfWNKY/serbia.png"
                alt="rs"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="titl__languages1">
        <h2>Kako naučiti strani jezik brzo i efikasno ?</h2>
        <h3>
          Naučiti strani jezik brzo i efikasno može biti izazovno, ali nije
          nemoguće.
        </h3>
        <h3>
          Naš program je fokusiran na praktičnoj primeni i upotrebi jezika u
          realnim situacijama.
        </h3>
        <h3>
          Uz pravilnu motivaciju i individualnu posvećenost pomoćićemo vam da
          bez obzira na vaš nivo znanja ostvarite Vaše ciljeve u što kraćem
          vremenskom periodu.
        </h3>
      </div>
    </div>
  );
};

export default LanguagesMain;
