import React from "react";
import { useNavigate } from "react-router-dom";
import "./serbien.css";

const Serbien = () => {
  const navigate = useNavigate();

  return (
    <div className="serbien" id="rs">
      <div className="container__rs">
        <img
          src="https://i.postimg.cc/ncMBBQBS/Wa9gkm-HOTf8-unsplash.jpg"
          alt="serbia"
        />
        <div className="col-2__de">
          <h2>Srpski jezik</h2>
          <span className="line"></span>
          <p>
            Srpski jezik je jedan od najvažnijih jezika u jugoistočnoj Evropi i
            zvanični je jezik u Srbiji, Bosni i Hercegovini, Crnoj Gori i
            delovima Hrvatske. Srpski jezik ima dugu istoriju i bogatu kulturnu
            baštinu, a govori ga preko 12 miliona ljudi širom sveta.
          </p>
          <p>
            Srbija i drugi delovi regiona se sve više otvaraju ka inostranstvu i
            tržištu rada postaje sve konkurentniji. Poznavanje srpskog jezika
            može biti prednost pri traženju posla u ovim zemljama i pomoći vam
            da se bolje povežete sa lokalnom zajednicom i poslovnim partnerima.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-srpskog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};

export default Serbien;
