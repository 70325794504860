import React from "react";
import { useNavigate } from "react-router-dom";

import "./home.css";

function Hero() {
  const navigate = useNavigate();

  return (
    <div className="home">
      <div className="container__Home">
        <h1>ONLINE ŠKOLA STRANIH JEZIKA</h1>
        <h2>NAUČITE STRANI JEZIK OD KUĆE</h2>
        <p>Prilagodite tempo i raspored učenja prema Vašim obavezama.</p>

        <button className="button__hero" onClick={() => navigate("/Kontakt")}>
          <span>PRIJAVITE SE</span>
        </button>
        <div class="social1">
          <img
            className="arrow__right"
            src="https://i.postimg.cc/5yFT6Bv0/028-right-arrow-1.png"
            alt="arrow-right"
          />
          <a
            href="https://www.facebook.com/OnlineSkolaStranihJezika/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/SRRfD7fk/facebook.png"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/akademija_level_up/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/8CNmfV32/Instagram-Logo-Transparent.png"
              alt="instagram"
            />
          </a>
          <a
            href="https://www.tiktok.com/@akademija_level_up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/qRDRp4bS/tiktok-Emailpng.png"
              alt="tiktok"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCgbWI-zAU5fP0dV2Hmd1H9w"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://i.postimg.cc/ZqKCzMbN/youtube-logo-png-2062.png"
              alt="youtube"
            />
          </a>
          <img
            className="arrow__right"
            src="https://i.postimg.cc/023NF6LZ/028-left-arrow-1.png"
            alt="arrow-right"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
