import React from "react";
import SectionSp from "./SectionSp";

const OnlineUcenjeSpanskogJezika = () => {
  return (
    <div>
      <SectionSp />
    </div>
  );
};

export default OnlineUcenjeSpanskogJezika;
