import React from "react";
import { FaQuestion } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./video.css";

const Video = () => {
  const navigate = useNavigate();

  return (
    <div className="video" id="video">
      <div className="container__video">
        <div className="col-1">
          <p>
            Da li Vam je potrebna pomoć oko prijave i registracije{" "}
            <FaQuestion />
          </p>
          <p>Pogledajte video</p>
          <p>
            U priloženom videu je detaljno objašnjenje{" "}
            <strong>korak po korak</strong> kako se prijaviti i podesiti
            aplikacije koje su Vam potrebne kako bi ste počeli sa učenjem
            odabranog jezika{" "}
          </p>
        </div>
        <div className="col-2">
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/Ba_wzrB0yrc`}
            title="Skola stranih jezika Level up - prijava i registracija"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="btn__div">
        <button className="button__video" onClick={() => navigate("/Kontakt")}>
          Počnite sa učenjem
        </button>
      </div>
    </div>
  );
};

export default Video;
