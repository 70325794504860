import React from "react";
import Contact from "../contact/Contact";
import Hero from "./Hero";
import LanguagesMain from "../../componnents/languages/LanguagesMain";
import Testemonial from "../testemonial/Testimonial";
import Faq from "../faq/Faq";
import Video from "../../componnents/video/Video";
import Price from "../price/Price";
import "./home__main.css";
const Home = () => {
  return (
    <div>
      <Hero />
      <Price />
      <LanguagesMain />
      <Video />
      <Testemonial />
      <Faq />
      <Contact />
    </div>
  );
};

export default Home;
