import React from "react";
import SectionDe from "./SectionDe";

const OnlineUcenjeNemackogJezika = () => {
  return (
    <div>
      <SectionDe />
    </div>
  );
};

export default OnlineUcenjeNemackogJezika;
