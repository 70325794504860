import React from "react";
import { useNavigate } from "react-router-dom";
import "./english.css";

const English = () => {
  const navigate = useNavigate();

  return (
    <div className="english" id="eng">
      <div className="container__eng">
        <img
          src="https://i.postimg.cc/SKbJzCLS/group-five-african-american-woman-walking-together-parking-with-great-britain-flag.jpg"
          alt="london"
        />
        <div className="col-2__eng">
          <h2>Engleski jezik</h2>
          <span className="line"></span>
          <p>
            Engleski jezik je jedan od najrasprostranjenijih i najkorisnijih
            jezika u svetu. Kao maternji jezik govori ga preko 400 miliona
            ljudi, a kao drugi jezik ili strani jezik govori ga preko milijardu
            ljudi širom sveta.
          </p>
          <p>
            Univerzalan jezik koji se koristi u poslovnom svetu, jezik interneta
            i moderne tehnologije. Poznavanje engleskog jezika može Vam otvoriti
            vrata u karijeri i pomoći vam da postignete svoje profesionalne
            ciljeve.
          </p>
          <button
            className="button__language"
            onClick={() => navigate("/Online-učenje-engleskog-jezika")}
          >
            Odaberite jezik
          </button>
        </div>
      </div>
    </div>
  );
};

export default English;
