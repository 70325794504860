import React from "react";
import SectionFr from "./SectionFr";

const OnlineUcenjeFrancuskogJezika = () => {
  return (
    <div>
      <SectionFr />
    </div>
  );
};

export default OnlineUcenjeFrancuskogJezika;
