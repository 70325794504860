import React from "react";
import English from "./english/English";
import Franch from "./franch/Franch";
import German from "./german/German";
import Italian from "./italian/Italian";
import Serbien from "./serbian/Serbien";
import Spain from "./spain/Spain";

const Languages = () => {
  return (
    <div>
      <English />
      <German />
      <Italian />
      <Spain />
      <Franch />
      <Serbien />
    </div>
  );
};

export default Languages;
