import React, { useState } from "react";
import FAQ from "./FaqExtra";
import "./faq.css";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();

  const [faqs, setfaqs] = useState([
    {
      question: "Kako da se prijavim ?",

      answer: (
        <div>
          <p>
            {" "}
            Postoji kratak video koji objašnjava korake za prijavu, a dugme
            <strong> Prijavi se</strong> vas vodi do stranice za prijavu. U
            produžetku teksta takodje možete doći do linka za prijavu
            jednostavnim klikom. Ako imate problema sa prijavom, možete nas
            kontaktirati u bilo kom trenutku i rado ćemo vam pomoći.
          </p>
          <span className="contact__span" onClick={() => navigate("/Kontakt")}>
            Kontaktirajte nas
          </span>
        </div>
      ),
    },
    {
      question: "Koje vreme je potrebno da bi se oformila grupa ?",
      answer: (
        <div>
          <p>
            {" "}
            Da bi se oformila grupa neka naša praksa i dosadašnje iskustvo
            pokazuje da je potrebno izmedju 5-10 dana. Naravno, vreme potrebno
            za formiranje grupe može da varira u zavisnosti od specifičnosti
            svakog pojedinačnog slučaja, kao i od brojnih drugih faktora koji
            mogu da utiču na proces formiranja grupe. U svakom slučaju, naš cilj
            je da pružimo najbolju moguću podršku našim klijentima i da
            osiguramo da oformljena grupa ispunjava sve njihove potrebe i
            ciljeve u naj kraćem mogućem vremenskom periodu.
          </p>
          <span className="contact__span" onClick={() => navigate("/Kontakt")}>
            Prijavite se ovde
          </span>
        </div>
      ),
    },
    {
      question: "Da li moguće da učim dva strana jezika istovremeno?",
      answer: `Da naravno da je moguće. Kod nas imate mogućnost pohadjanja više od jednog stranog jazika, u koliko se za to odlučite.`,
    },
    {
      question: "Koliko je potrebno vremena da se savlada jedan jezicki nivo?",
      answer: `Vreme potrebno da se savlada jedan jezički nivo zavisi od nekoliko faktora, uključujući: Prethodno znanje jezika, redovnost u učenju, intenzitet učenja, metode učenja. Opšte pravilo je da je za savladavanje jednog jezičkog nivoa potrebno najmanje 200 sati učenja. Međutim, ovo može varirati u zavisnosti od gore navedenih faktora. Takođe, važno je napomenuti da savladavanje nivoa jezika ne znači da ste postali apsolutno perfektni u govoru i razumevanju, već da ste postigli određeni nivo kompetentnosti. `,
    },
    {
      question: "Kako funkcioniše uplata?",
      answer: `Nakon uspešne prijave i nakon formiranja grupe dobićete E-mail sa kompletnim uputstvom za uplatu. U slučaju da se odlučite za individualnu nastavu odmah nakon prijave dobićete E-mail sa uptutstvom za uplatu. U svakom trenutku možete kontaktirati našu tehničku podršku koja je tu za Vas 24/7.`,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div className="faqs__main__container">
      <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
